<template>
  <v-sheet
    class="background-primary-gradient rounded-border-25"
  >
    <v-row
      align="center"
      justify="center"
      class="px-3"
    >
      <!-- <v-col
        cols="12"
        sm="4"
        class="pa-6 text-center"
      >
        <base-avatar
          :size="strAvatarSize"
        >
          <base-img
            :src="require(`@/assets/icons/${objReviewer.strImgPath}`)"
            contain
          />
        </base-avatar>
      </v-col>

      <v-col
        cols="12"
        sm="8"
        class="pa-6"
      > -->
      <v-col
        cols="12"
        class="pa-6"
      >
        <component
          :is="m_objTitle.strComponent"
          :v-if="m_objTitle"
          :class="getTextClasses(m_objTitle.eType, ['white--text'])"
        >
          {{ m_objTitle.strText }}
        </component>

        <component
          :is="m_objSubtitle.strComponent"
          :v-if="m_objSubtitle"
          :class="getTextClasses(m_objSubtitle.eType, ['white--text'])"
        >
          {{ m_objSubtitle.strText }}
        </component>

        <component
          :is="m_objBody.strComponent"
          :v-if="m_objBody"
          :class="getTextClasses(m_objBody.eType, ['white--text', 'font-italic'])"
        >
          {{ m_objBody.strText }}
        </component>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
  import TextClass from '@/mixins/text-class'

  export default {
    name: 'KDAPortfolioReviewerCard',

    mixins: [TextClass],

    props: {
      objReviewer: {
        type: Object,
        required: true,
      },
    },

    computed: {
      m_objTitle () {
        return {
          strText: this.objReviewer.strName,
          strComponent: 'h1',
          eType: this.eContentType.TITLE_3,
        }
      },

      m_objSubtitle () {
        return {
          strText: this.objReviewer.strTitle,
          strComponent: 'p',
          eType: this.eContentType.SUBTITLE_3,
        }
      },

      m_objBody () {
        return {
          strText: this.objReviewer.strQuote,
          strComponent: 'p',
          eType: this.eContentType.BODY_1,
        }
      },

      strAvatarSize () {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return '250'
        } else if (this.$vuetify.breakpoint.smAndUp) {
          return '200'
        } else {
          return '150'
        }
      },
    },
  }
</script>

<style lang="sass">
  .background-primary-gradient
    background: linear-gradient(to right,$accent1, $accent3)

  .rounded-border-25
    border-radius: 25px
</style>
